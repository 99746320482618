<script>
  import { auth } from "../../services/auth.js";

  import Table from "../../components/Table.svelte";
  import urlService from "../../services/url";
  import { onMount } from "svelte";
  import * as downloadjs from "downloadjs";

  import Code from "../../components/Code.svelte";
  import { getContext } from "svelte";
  const { open } = getContext("simple-modal");

  export let path = null;

  const urls = urlService();
  let json = {};

  let options = {
    pagination: "local",
    paginationSize: 25,
  };

  // @todo: there's probably a way to get this data from the table without running another call. but meh.
  onMount(async () => {
    let auth0Client = await auth.createClient();
    let token = await auth0Client.getTokenSilently();

    let r = await fetch(`${urls.chartroomApi}/documents/bylayer/${path}`, {
      headers: {
        Authorization: "JWT " + token,
      },
      method: "GET",
    });

    json = await r.json();
  });

  async function download() {
    /// todo replace this nonsense with https://github.com/mikeal/bent
    try {
      let response = await fetch(`${urls.functions}/api/download`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          files: json,
        }),
      });

      if (response.status != 200) {
        open(Code, { code: await response.text() });
      } else {
        downloadjs.default(await response.blob());
      }
    } catch (e) {
      alert(e);
    }
  }
</script>

<Table path="documents/bylayer/{path}" {options} />

<hr />

<button on:click={download}>Download All Files</button>
