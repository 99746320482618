<script>
  // https://svelte.dev/repl/cae157f5b9834d1482a0be49122a9edf?version=3.22.2

  import { getContext } from "svelte";

  import Modal from "svelte-simple-modal";
  import Tabulator from "tabulator-tables";
  import Cookies from "js-cookie";
  import { navigate } from "svelte-routing";

  import Json from "./Json.svelte";
  import Code from "./Code.svelte";
  import NavBar from "./NavBar.svelte";
  import { auth, authToken, isAuthenticated } from "../services/auth.js";

  import urlService from "../services/url";
  const urls = urlService();

  export let path;
  export let columns = null;
  export let options = null;

  const { open } = getContext("simple-modal");

  let table;
  let data;

  async function tableAction(node, { path, columns, options }) {
    let auth0Client = await auth.createClient();
    let token = await auth0Client.getTokenSilently();

    let opts = {
      dataTree: true,
      layout: "fitColumns",
      // rowDblClick: function(e, row) {
      //   open(Json, { json: row.getData() });
      // },
      cellClick: function (e, cell) {
        const cellValue = cell.getValue();

        if (typeof cellValue == "object") {
          open(Json, { json: cellValue });
        } else {
          open(Code, { code: cellValue });
        }
      },
    };

    if (!columns) {
      opts.autoColumns = true;
    } else {
      opts.columns = columns;
    }

    if (options) {
      opts = { ...opts, ...options };
    }

    let r = await fetch(`${urls.chartroomApi}/${path}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
      method: "GET",
    });

    try {
      data = await r.json();
      opts.data = data;

      if (!Array.isArray(opts.data)) {
        opts.data = [opts.data];
      }
    } catch (e) {
      console.log(e);
    }

    let table = new Tabulator(node, opts);

    return {
      update: ({ path }) => {
        table.replaceData(path);
      },
      // destroy: () => alert('bye bye table :\'(')
    };
  }
</script>

<NavBar
  header={path}
  {data}
  upBtn={location.pathname != "/"}
  downloadBtn={path}
  editorBtn
/>

<div use:tableAction={{ path: path, options: options, columns: columns }}>
  Loading...
</div>

<svelte:head>
  <link
    href="https://unpkg.com/tabulator-tables@4.6.2/dist/css/tabulator.min.css"
    rel="stylesheet"
  />
</svelte:head>
