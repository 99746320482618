<script>
  export let editor;
  import { auth } from "../../services/auth.js";

  import constructPath from "../../services/path.js";
  const currentObject = constructPath();

  import urlService from "../../services/url";
  const urls = urlService();

  import download from "../../services/download.js";

  import Cookies from "js-cookie";
  import Code from "../Code.svelte";
  import { getContext } from "svelte";
  import Modal from "svelte-simple-modal";
  const { open } = getContext("simple-modal");

  async function update(json) {
    let auth0Client = await auth.createClient();
    let token = await auth0Client.getTokenSilently();

    const path = `${urls.chartroomApi}/${currentObject}`;

    const confirmation = confirm(`Uploading updated JSON to ${path}`);

    if (confirmation) {
      try {
        let r = await fetch(path, {
          body: JSON.stringify(json),
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + token,
          },
          method: "PUT",
        });
        open(Code, { code: `${JSON.stringify(await r.json())}` });
      } catch (error) {
        open(Code, { code: `${error}` });
      }
    }
  }

  async function updateBtnClick() {
    const json = editor.get();

    download(json);
    update(json);
  }
</script>

<button on:click={updateBtnClick}>Update</button>
