<script>
  import { Router, Route, Link } from "svelte-routing";

  import Modal from "svelte-simple-modal";
  import Cookies from "js-cookie";

  import Table from "./components/Table.svelte";

  import Json from "./routes/Json.svelte";
  import User from "./routes/User.svelte";
  import MapServices from "./routes/MapServices.svelte";
  import Layer from "./routes/layers/Layer.svelte";
  import Layers from "./routes/layers/Layers.svelte";
  import LayersByService from "./routes/layers/ByService.svelte";
  import FeaturesByLayer from "./routes/features/ByLayer.svelte";
  import DocumentsByLayer from "./routes/documents/ByLayer.svelte";

  import { onMount } from "svelte";

  import { auth, authToken, isAuthenticated } from "./services/auth.js";

  let auth0Client;

  onMount(async () => {
    auth0Client = await auth.createClient();

    await auth.handleRedirect(auth0Client);
  });

  export let url = ""; //This property is necessary declare to avoid ignore the Router
</script>

<Router {url}>
  <nav>
    <a href="/">Home</a>
    <a href="/currentuser">CurrentUser</a>
    <a href="/mapservices">Services</a>
    <a href="/layers">Layers</a>
    <a href="/layouts">Layouts</a>
    <a href="/groups">Groups</a>
    <a href="/user">Users</a>

    {#if $isAuthenticated}
      <button on:click={() => auth.logout(auth0Client)}>Logout</button>
    {:else}
      <button on:click={() => auth.login(auth0Client)}>Login</button>
    {/if}
  </nav>
  <div>
    {#if $isAuthenticated}
      <Modal>
        <Route path="/">
          <h1>Welcome to the Chartroom API Explorer!</h1>
          <p>Click on the links above to begin navigating the API.</p>
        </Route>

        <Route path="/json/*path" component={Json} />
        <Route path="/user" component={User} />
        <Route path="/layers/byservice/*path" component={LayersByService} />
        <Route path="/layers" component={Layers} />
        <Route path="/layers/*path" component={Layer} />
        <Route path="/features/bylayer/*path" component={FeaturesByLayer} />
        <Route path="/mapservices" component={MapServices} />
        <Route path="/documents/bylayer/*path" component={DocumentsByLayer} />

        <Route path="/*path" component={Table} />
      </Modal>
    {/if}
  </div>
</Router>
