import constructPath from "./path";

export default function (json, name = constructPath("_")) {
  // http://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser

  const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(json, null, 2));

  const downloadAnchorNode = document.createElement("a");

  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", `${name}.json`);

  document.body.appendChild(downloadAnchorNode);

  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}
