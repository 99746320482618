<script>
  import Table from "../components/Table.svelte";

  let columns = [
    {
      field: "name",
      title: "name",
      headerFilter: true
    },
    {
      field: "id",
      title: "id",
      formatter: "link",
      headerFilter: true,
      formatterParams: {
        url: function(row) {
          let id = row.getData().id;
          return `/layers/byservice/${id}/layers`;
        }
      }
    },
    {
      headerFilter: "select",
      headerFilterParams: {
        true: "true",
        false: "false"
      },
      field: "external",
      title: "external"
    },
    {
      headerFilter: "select",
      headerFilterParams: {
        true: "true",
        false: "false"
      },
      field: "defaultVisibility",
      title: "defaultVisibility"
    },

    {
      field: "createdAt",
      title: "createdAt",
      headerFilter: true
    },
    {
      field: "updatedAt",
      title: "updatedAt",
      headerFilter: true
    }
  ];

  let options = {
    pagination: "local",
    paginationSize: 25
  };
</script>

<Table path="mapservices" {columns} {options} />
