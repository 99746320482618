import { createAuth0Client, Auth0Client } from "@auth0/auth0-spa-js";

import { get, writable } from "svelte/store";
export const loading = writable(true);
export const isAuthenticated = writable(false);
export const user = writable({});
export const authToken = writable("");
export const popupOpen = writable(false);
export const error = writable();

async function createClient() {
  const auth0 = new Auth0Client({
    domain: "hbkapps-production.us.auth0.com",
    client_id: "LFFmCUcCOAub8LKAKLC5P4eyDSF3iB1L",
    cacheLocation: "localstorage",
    audience: "https://api.production.hbkapps.com",
  });

  return auth0;
}

async function handleRedirect(client) {
  // Not all browsers support this, please program defensively!
  const params = new URLSearchParams(window.location.search);

  // if code then login success
  if (params.has("code")) {
    // Let the Auth0 SDK do it's stuff - save some state, etc.
    await client.handleRedirectCallback();
    // Can be smart here and redirect to original path instead of root
    window.history.replaceState({}, document.title, "/");
  }

  const _isAuthenticated = await client.isAuthenticated();
  isAuthenticated.set(_isAuthenticated);

  if (_isAuthenticated) {
    user.set(await client.getUser());
    console.log(await client.getTokenSilently());
  }
}

async function login(client) {
  await client.loginWithRedirect({
    redirect_uri: window.location.origin,
    prompt: "login",
  });
}

function logout(client) {
  return client.logout({ returnTo: window.location.origin });
}

export const auth = {
  createClient,
  login,
  logout,
  handleRedirect,
};
