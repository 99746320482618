<script>
  import Code from "../Code.svelte";

  import urlService from "../../services/url";
  const urls = urlService();

  let validation;

  export let editor;

  async function validate() {
    let response = await fetch(`${urls.functions}/api/validate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(editor.get())
    });

    validation = await response.json();

    if (validation.length) {
      validation = JSON.stringify(validation);
    } else {
      validation = "all ok!";
    }
  }
</script>

<button on:click={validate}>Validate GeoJSON</button>
{#if validation}
  <hr />
  <Code code={validation} />
{/if}
