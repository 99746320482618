<script>
  import Table from "../../components/Table.svelte";

  let columns = [
    {
      field: "id",
      title: "id",
      headerFilter: true,
      formatter: "link",
      formatterParams: {
        url: function(row) {
          let id = row.getData().id;
          return `/features/bylayer/${id}`;
        }
      }
    },
    {
      field: "owner",
      title: "owner",
      headerFilter: true,
      formatter: "link",
      formatterParams: {
        url: function(row) {
          let id = row.getData().owner;
          return `/user/${id}`;
        }
      }
    },
    {
      field: "service",
      title: "service",
      headerFilter: true,
      formatter: "link",
      formatterParams: {
        url: function(row) {
          let id = row.getData().service;
          return `/mapservices/${id}`;
        }
      }
    },
    {
      field: "name",
      title: "name",
      headerFilter: true
    },
    {
      field: "position",
      title: "position",
      headerFilter: true
    },
    {
      field: "description",
      title: "description",
      headerFilter: true
    },
    {
      field: "hasFiles",
      title: "hasFiles",
      headerFilter: "select",
      headerFilterParams: {
        true: "true",
        false: "false"
      }
    },
    {
      field: "hasGeometry",
      title: "hasGeometry",
      headerFilter: "select",
      headerFilterParams: {
        true: "true",
        false: "false"
      }
    },
    {
      field: "appearance",
      title: "appearance",
      headerFilter: true
    },
    {
      field: "opacity",
      title: "opacity",
      headerFilter: true
    },
    {
      field: "objectCount",
      title: "objectCount",
      headerFilter: true
    },
    {
      field: "createdAt",
      title: "createdAt",
      headerFilter: true
    },
    {
      field: "updatedAt",
      title: "updatedAt",
      headerFilter: true
    }
  ];
  let options = {
    pagination: "local",
    paginationSize: 25
  };
</script>

<Table path="layers" {options} {columns} />
