<script>
  import JSONEditor from "jsoneditor";
  import Transform from "./Json/Transform.svelte";
  import Validate from "./Json/Validate.svelte";
  import Download from "./Json/Download.svelte";
  import Update from "./Json/Update.svelte";

  export let json;

  let editor;

  function jsonAction(node, { json }) {
    // create the editor
    const container = node;
    const options = {
      mode: "tree",
      modes: ["text", "tree"],
      onEditable: function(node) {
        if (!node.path) {
          return false;
        }
        return true;
      },
      onError: function(err) {
        alert(err.toString());
      }
    };

    editor = new JSONEditor(container, options);

    editor.set(json);
  }
</script>

<div use:jsonAction={{ json }} />
<hr />

<Download {editor} />
<Transform {editor} />
<Validate {editor} />
<Update {editor} />

<link
  href="https://unpkg.com/jsoneditor@9.0.1/dist/jsoneditor.min.css"
  rel="stylesheet" />
