<script>
  import Table from "../components/Table.svelte";

  let columns = [
    {
      field: "id",
      title: "id",
      headerFilter: true,
      formatter: "link",
      formatterParams: {
        url: function(row) {
          let id = row.getData().id;
          return `/user/${id}`;
        }
      }
    },
    {
      field: "Username",
      title: "Username",
      headerFilter: true
    },
    {
      field: "Organization",
      title: "Organization",
      headerFilter: true,
      formatter: "link",
      formatterParams: {
        url: function(row) {
          let id = row.getData().Organization;
          return `/organization/${id}`;
        }
      }
    },
    {
      field: "PrimaryName",
      title: "PrimaryName",
      headerFilter: true
    },
    {
      field: "SecondaryName",
      title: "SecondaryName"
    },
    {
      field: "EmailAddress",
      title: "EmailAddress",
      headerFilter: true
    },

    {
      field: "Organization",
      title: "Organization",
      headerFilter: true
    },
    {
      field: "hasPassword",
      title: "hasPassword",
      headerFilter: true,
      headerFilter: "select",
      headerFilterParams: {
        true: "true",
        false: "false"
      }
    },
    {
      field: "groups",
      title: "groups",
      headerFilter: true
    },
    {
      field: "Status",
      title: "Status",
      headerFilter: true
    },
    {
      field: "lastLogin",
      title: "lastLogin",
      headerFilter: true
    },
    {
      field: "_created",
      title: "_created",
      headerFilter: true
    },
    {
      field: "_modified",
      title: "_modified",
      headerFilter: true
    },

    {
      field: "updatedAt",
      title: "createdAt",
      headerFilter: true
    },
    {
      field: "creationEmailSent",
      title: "creationEmailSent",
      headerFilter: true
    }
  ];

  let options = {
    pagination: "local",
    paginationSize: 25
  };
</script>

<Table path="user" {columns} {options} />
