<script>
  import Json from "../../components/Json.svelte";
  import Table from "../../components/Table.svelte";
  import { jsonFormatter } from "../../utils.js";

  export let path;

  let columns = [
    {
      field: "id",
      title: "id",
      formatter: "link",
      headerFilter: true,
      formatterParams: {
        url: function(row) {
          //   let id = row.getData().id;
          //   return `/features/bylayer/${id}`;
        }
      }
    },
    {
      field: "dataType",
      title: "dataType",
      headerFilter: true
    },
    {
      field: "type",
      title: "type",
      headerFilter: true
    },
    {
      field: "properties",
      title: "properties",
      headerFilter: true,
      formatter: jsonFormatter
    },
    {
      field: "geometry",
      title: "geometry",
      headerFilter: true,
      formatter: jsonFormatter
    },
    {
      field: "createdBy",
      title: "createdBy",
      headerFilter: true
    },
    {
      field: "createdAt",
      title: "createdAt",
      headerFilter: true
    },
    {
      field: "updatedAt",
      title: "updatedAt",
      headerFilter: true
    }
  ];

  let options = {
    pagination: "local",
    paginationSize: 25
  };
</script>

<Table path="features/bylayer/{path}" {columns} {options} />

<hr />
<a href="/documents/bylayer/{path}">Find Documents From Layer</a>
