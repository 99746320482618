<script>
  import { onMount } from "svelte";

  export let editor;
  let transformers = [];
  let selected;

  import urlService from "../../services/url";
  const urls = urlService();

  onMount(async () => {
    let r = await fetch(`${urls.functions}/api/transform`, {
      method: "GET"
    });

    transformers = await r.json();
  });

  async function transform() {
    let response = await fetch(
      `${urls.functions}/api/transform?transformer=${selected}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8"
        },
        body: JSON.stringify(editor.get())
      }
    );

    const transformation = await response.json();

    editor.set(transformation);
  }
</script>

<select bind:value={selected}>
  <option />
  {#each Object.keys(transformers) as transformer}
    <option value={transformer}>{transformers[transformer].name}</option>
  {/each}
</select>
<button on:click={transform}>Transform JSON</button>
