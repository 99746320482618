export default function () {
  // default to staging
  let urls = {
    chartroomApi: "https://api.production.hbkapps.com", // no leading "/"
    functions: "", // use relative paths by default
    auth: "https://auth.staging.hbkapps.com",
  };

  switch (location.hostname) {
    case "localhost": {
      urls.functions = "http://localhost:7071";
    }
    case "chartroom-api-explorer.hbkapps.net": // production
      urls.chartroomApi = "https://api.production.hbkapps.com";
      urls.auth = "https://auth.hbkapps.com";
  }

  console.log("Using URLS: ", urls);

  return urls;
}
