<script>
  import { navigate } from "svelte-routing";
  import download from "../services/download.js";

  export let upBtn = false,
    downloadBtn = false,
    editorBtn = false,
    data = false,
    header;

  function onDownloadBtnClick() {
    download(data);
  }

  function navigateUp() {
    if (location.pathname != "/") {
      let pathname = location.pathname.split("/");
      pathname.shift();
      pathname.pop();

      let newPath = pathname.join("/");

      navigate(`/${newPath}`);
    } else {
      alert("can't go up any further");
    }
  }

  function openInEditor() {
    navigate(`/json${location.pathname}`);
  }

  function navigateBack() {
    history.back();
  }
</script>

<h1>
  <code>{header}</code>
</h1>

<button on:click={navigateBack}>Back</button>

{#if upBtn}
  <button on:click={navigateUp}>Up</button>
{/if}

{#if data && downloadBtn}
  <button on:click={onDownloadBtnClick}>Download</button>
{/if}

{#if data && editorBtn}
  <button on:click={openInEditor}>Open in Editor</button>
{/if}

<span />
