<script>
  import Table from "../../components/Table.svelte";
  export let path = null;

  let options = {
    pagination: "local",
    paginationSize: 25
  };
</script>

<Table path="layers/{path}" {options} />
