<script>
  export let editor;

  import download from "../../services/download.js";

  function onDownloadBtnClick() {
    const json = editor.get();

    download(json);
  }
</script>

<button on:click={onDownloadBtnClick}>Download</button>
