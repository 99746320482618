<script>
  import { onMount } from "svelte";
  import { auth } from "../services/auth.js";

  import urlService from "../services/url";
  const urls = urlService();

  import Json from "../components/Json.svelte";
  import NavBar from "../components/NavBar.svelte";

  export let path;
  let json;

  onMount(async () => {
    let auth0Client = await auth.createClient();
    let token = await auth0Client.getTokenSilently();

    let r = await fetch(`${urls.chartroomApi}/${path}`, {
      headers: {
        Authorization: "JWT " + token,
      },
      method: "GET",
    });

    json = await r.json();
  });
</script>

<NavBar header={path} />

{#if json}
  <Json {json} />
{/if}
